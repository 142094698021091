<template>
  <div>
    <div class="das_top_cont">
      <div class="history_box account_input">
        <div class="accountright_cont_box">
          <h2 class="history_box-head spec_title">Credit Deductions</h2>
          <div class="his_sel_box" style="width: 100%">
            <el-table class="spec_table table_height_30 table_font_size_14" :data="yourUsagelist" style="width: 100%" empty-text="No Data">
              <el-table-column prop="createTime" label="Date" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="UserAccount" label="User Account" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.email }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="companyName" label="Company Name" align="left"show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content company_link" @click="setid(scope.row)" style="">
                    {{ scope.row.companyName }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="consumptionPoints" label="Credits Deducted" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.consumptionPoints }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="cpmNoStatus" label="Report Order Status" align="left" show-overflow-tooltip
                width="240">
                <template slot-scope="scope">
                  <span class="table-content" v-if="scope.row.cpmNoStatus == 1">Finished </span>
                  <span class="table-content" v-if="scope.row.cpmNoStatus == 2">In progress</span>
                  <span class="table-content" v-if="scope.row.cpmNoStatus == 3">Production failed</span>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="" label="Download" align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                  <span class="table-content flex_center" @click="Down(scope.row)">
                    <svg t="1723022129119" class="icon" v-if="scope.row.isDownloadFile==false" viewBox="0 0 1024 1024"
                      version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6324" width="28" height="28">
                      <path
                        d="M491.52 858.112l-108.544-106.496c-8.192-8.192-20.48-8.192-28.672 0-8.192 8.192-8.192 20.48 0 28.672l139.264 135.168c0 2.048 2.048 6.144 4.096 8.192 4.096 4.096 10.24 6.144 16.384 6.144 6.144 0 12.288-2.048 16.384-6.144 2.048-2.048 4.096-4.096 4.096-8.192l139.264-135.168c8.192-8.192 8.192-20.48 0-28.672-8.192-8.192-20.48-8.192-28.672 0l-110.592 108.544v-368.64c0-12.288-8.192-20.48-20.48-20.48s-20.48 8.192-20.48 20.48v366.592z m-399.36-344.064v-307.2c0-45.056 36.864-81.92 81.92-81.92h675.84c45.056 0 81.92 36.864 81.92 81.92v307.2c0 45.056-36.864 81.92-81.92 81.92h-96.256c-12.288 0-20.48 8.192-20.48 20.48s8.192 20.48 20.48 20.48h96.256c67.584 0 122.88-55.296 122.88-122.88v-307.2c0-67.584-55.296-122.88-122.88-122.88h-675.84c-67.584 0-122.88 55.296-122.88 122.88v307.2c0 67.584 55.296 122.88 122.88 122.88v-40.96h-4.096v40.96h102.4c12.288 0 20.48-8.192 20.48-20.48s-8.192-20.48-20.48-20.48h-98.304c-45.056 0-81.92-36.864-81.92-81.92z m296.96-237.568c-12.288 0-20.48 8.192-20.48 20.48s8.192 20.48 20.48 20.48h245.76c12.288 0 20.48-8.192 20.48-20.48s-8.192-20.48-20.48-20.48h-245.76z"
                        fill="#1296db" p-id="6325"></path>
                    </svg>
                    <svg t="1725877594421" class="icon" v-else viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="5401" width="28" height="28">
                      <path
                        d="M491.52 858.112l-108.544-106.496c-8.192-8.192-20.48-8.192-28.672 0-8.192 8.192-8.192 20.48 0 28.672l139.264 135.168c0 2.048 2.048 6.144 4.096 8.192 4.096 4.096 10.24 6.144 16.384 6.144 6.144 0 12.288-2.048 16.384-6.144 2.048-2.048 4.096-4.096 4.096-8.192l139.264-135.168c8.192-8.192 8.192-20.48 0-28.672-8.192-8.192-20.48-8.192-28.672 0l-110.592 108.544v-368.64c0-12.288-8.192-20.48-20.48-20.48s-20.48 8.192-20.48 20.48v366.592z m-399.36-344.064v-307.2c0-45.056 36.864-81.92 81.92-81.92h675.84c45.056 0 81.92 36.864 81.92 81.92v307.2c0 45.056-36.864 81.92-81.92 81.92h-96.256c-12.288 0-20.48 8.192-20.48 20.48s8.192 20.48 20.48 20.48h96.256c67.584 0 122.88-55.296 122.88-122.88v-307.2c0-67.584-55.296-122.88-122.88-122.88h-675.84c-67.584 0-122.88 55.296-122.88 122.88v307.2c0 67.584 55.296 122.88 122.88 122.88v-40.96h-4.096v40.96h102.4c12.288 0 20.48-8.192 20.48-20.48s-8.192-20.48-20.48-20.48h-98.304c-45.056 0-81.92-36.864-81.92-81.92z m296.96-237.568c-12.288 0-20.48 8.192-20.48 20.48s8.192 20.48 20.48 20.48h245.76c12.288 0 20.48-8.192 20.48-20.48s-8.192-20.48-20.48-20.48h-245.76z"
                        fill="#999999" p-id="5402"></path>
                    </svg>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align:  center; padding-top: 20px" v-if="this.page.total>this.page.pageSize" class="show_AllHeight">
              <span class="contclass show_All" @click="apptablelist">Show All</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";
import { getUserConsumptionPage, getbillingAndPaymentStatistics, getUserRechargesPdfAddress } from "@/api/userApi";
import { getOfflinePdf, } from "@/api/userApi";
import ReLogin from "../../../components/ReLogin.vue";

export default {
  mixins: [mixins1],
  components: {
    ReLogin
  },
  data() {
    return {
      reLoginVis: false,
      value1: "",
      CategoryVal: "",
      IndicatorsValType: '',
      type: [],
      IndicatorsValStatus: '',
      yourUsagelist: [],
      window: window,
      page: {
        pageSize: 10,
        pageNum: 1,
        total:0
      },
    };
  },
  beforeDestroy() { },
  destroyed() { },
  mounted() {
    this.getgetUserActionLogPage();
  },

  methods: {
         // 点击跳转
         setid(row) {

   
let companyCountryEn = "";

let routeData = this.$router.resolve({
  path:
    "/index/company/detals/" +
    row.companyAaaid +
    "/background/snapshot?id3a=" +
    row.companyAaaid +
    "&companyCountry=" +
    ''+
    "&companyCountryEn=" +
    companyCountryEn,
});

window.open(routeData.href, "_blank");

},
    //下载
    Down(scope) {
      if (scope.isDownloadFile == false) {
        getOfflinePdf(scope.id).then(res => {
          if (res.code == 200) {
            window.open(res.data, '_blank');
            this.getgetUserActionLogPage()

          } else {
            this.$message.error(res.msg)
          }

        })
        // window.open(scope.fileUrl, '_blank');
      } else {

      }

    },
    // 获取页面数据
    getgetUserActionLogPage() {
      getUserConsumptionPage({
        page: this.page.pageNum,
        pageSize: this.page.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.yourUsagelist = res.data.rows;
          this.page.total=res.data.total
        } else {
          this.yourUsagelist = [];
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });

    },

    apptablelist() {
      this.page.pageSize= this.page.pageSize+5
      this.getgetUserActionLogPage()
    },
  },
};
</script>
<style scoped>
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

.form-email {
  display: flex;
  width: 100%;
}

.block-con-body-text {
  word-break: keep-all;
  text-align: justify;
  font-size: 1rem;
  color: #022955;
}

.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.item_txtcolor1 {
  color: #1290c9;
}

.item_txtcolor2 {
  color: #55b419;
}

.item_txtcolor3 {
  color: #ffa000;
}

.das_top_item .color1 {
  background: rgba(18, 144, 201, 0.12);
}

.das_top_item .color2 {
  background: rgba(85, 180, 25, 0.12);
}

.das_top_item .color3 {
  background: rgba(255, 160, 0, 0.12);
}

.das_top_item .color4 {
  background: #ff7600;
}

.das_top_item .item_img img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: left;
}

.das_top_item .item_group {
  margin-left: 0.7142857142857143rem;
  font-size: 1.2857142857142858rem;

  /* width: 17.142857142857142rem; */
}

.das_top_item .item_lab {
  display: block;
  color: #1a2332;

  margin-top: 0.35714285714285715rem;
}

.item_txt {
  font-size: 2.142857142857143rem;
  text-align: center;
}

.cont_m {
  margin-top: 2.142857142857143rem;
  margin-bottom: 5.714285714285714rem;
}

.country_box {
  margin-top: 4.285714285714286rem;
}

.country_box .title {
  margin-bottom: 0;
}

.title {
  margin-bottom: 1.4285714285714286rem;
}

.title_txt {
  font-size: 1.1428571428571428rem;
  font-family: 'DMSans Bold';
}

.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}

.c_d_buttom {
  justify-content: flex-end;
  /* margin-top: 1.4285714285714286rem; */
  margin-bottom: 0;
}

.btn_blue {
  padding: 0.7142857142857143rem 1.4285714285714286rem;
  background: #1290c9;
  font-size: 1.2857142857142858rem;
  color: #ffffff;
  cursor: pointer;
}

.btn_blu {
  margin-right: 1.4285714285714286rem;
}

.btn_blue img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
  margin-right: 0.42857142857142855rem;
}

.sel_box {
  margin-right: 1.7857142857142858rem;
  /* width: 23.928571428571427rem; */
  height: 2.857142857142857rem;
}

.sel_box /deep/ .el-input__inner {
  border-color: #ccc;
  border-radius: 0.5714285714285714rem;
  font-size: 1.1428571428571428rem;
}

.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.das_top_item {
  width: 32.5%;
  padding: 1.4285714285714286rem;
  /* border-radius: 1rem; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
}

.das_top_item .item_img {
  width: 4.285714285714286rem;
  height: 4.285714285714286rem;
  line-height: 5.571428571428571rem;
  text-align: center;
  border-radius: 50%;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: center;
}

.top_tab {
  margin-top: 2.857142857142857rem;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286rem;
  font-size: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  /* line-height: 2.142857142857143rem; */
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: 'DMSans Bold';
}

.last-colmun {
  padding: 2rem 0rem;
  border-bottom: 0.07142857142857142rem solid #ebeef5;
}

.dingwei {
  position: absolute;
  top: -2.142857142857143rem;
  right: 0rem;
}

/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143rem !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 0.07142857142857142rem dashed #d9d9d9;
  border-radius: 0.42857142857142855rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 1.4285714285714286rem;
  color: #8c939d;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
}

/deep/ .avatar {
  width: 5rem;
  height: 5rem;
  display: block;
}

/* 日历大小 */
.el-range-editor.el-input__inner {
  /* width: 16rem !important; */
  padding: 0.21428571428571427rem 0.21428571428571427rem;
}

.el-date-editor /deep/ .el-range__icon {
  font-size: 1.1428571428571428rem;
  margin-left: -0.35714285714285715rem;
  color: #1A2332;
  /* float: left; */
  line-height: 2.2857142857142856rem;
  position: relative;
  left: 47%;
}
@media screen and (max-width: 1441px) {
  .btn_blue {
    font-size: 14px;
    width: max-content;
  }
}
</style>